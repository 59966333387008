@charset "UTF-8";

$openstudio-fonts-url: $base-url + 'fonts/' !default;

@font-face {
	font-family: 'openstudio';
	src:url('#{$openstudio-fonts-url}openstudio.eot?gcdybx');
	src:url('#{$openstudio-fonts-url}openstudio.eot?#iefixgcdybx') format('embedded-opentype'),
	url('#{$openstudio-fonts-url}openstudio.woff?gcdybx') format('woff'),
	url('#{$openstudio-fonts-url}openstudio.ttf?gcdybx') format('truetype'),
	url('#{$openstudio-fonts-url}openstudio.svg?gcdybx#openstudio') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'openstudio';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-studio:before {
	content: "\e614";
}
.icon-search-small:before {
	content: "\e601";
}
.icon-search:before {
	content: "\e602";
}
.icon-previous:before {
	content: "\e603";
}
.icon-preview:before {
	content: "\e604";
}
.icon-next:before {
	content: "\e605";
}
.icon-logout:before {
	content: "\e606";
}
.icon-languages:before {
	content: "\e607";
}
.icon-home:before {
	content: "\e608";
}
.icon-graph-pie:before {
	content: "\e609";
}
.icon-fullscreen:before {
	content: "\e60a";
}
.icon-dropdown:before {
	content: "\e60b";
}
.icon-download-small:before {
	content: "\e60c";
}
.icon-download:before {
	content: "\e60d";
}
.icon-delimiter:before {
	content: "\e60e";
}
.icon-close-preview:before {
	content: "\e60f";
}
.icon-close:before {
	content: "\e610";
}
.icon-breadcrumb:before {
	content: "\e611";
}
.icon-basket-remove:before {
	content: "\e612";
}
.icon-basket-add-small:before {
	content: "\e613";
}
.icon-basket-added:before {
	content: "\e617";
}
.icon-basket-add:before {
	content: "\e615";
}
.icon-basket:before {
	content: "\e616";
}

