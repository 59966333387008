@charset "UTF-8";
@mixin opacity($op) {
	@if $op == 100 {
		opacity: 1;
	}
	@else {
		opacity: #{"." + $op};
	}
	filter: alpha(opacity=$op);
	-ms-filter: "alpha(opacity=#{$op})";
}

@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

@mixin transform-style($val) {
	-webkit-transform-style: $val;
	-moz-transform-style: $val;
	-ms-transform-style: $val;
	transform-style: $val;
}

@mixin perspective($val) {
	-webkit-perspective: $val;
	-moz-perspective: $val;
	-ms-perspective: $val;
	perspective: $val;
}

@mixin perspective-origin($val){
	-webkit-perspective-origin: $val;
	-moz-perspective-origin: $val;
	-ms-perspective-origin: $val;
	perspective-origin: $val;
}

@mixin border-radius($radius: 5px) {
	-moz-border-radius:    $radius;
	-webkit-border-radius: $radius;
	border-radius:         $radius;
}

html {
	overflow-x: hidden;
	@media #{$small-only} {
		font-size: 50%;
	}
	color: $color;
}

body {
	overflow: hidden;
	width: 100%;
	> .container {
		position: absolute;
		width: 100%;
		overflow: hidden;
		top: 0;
		bottom: 0;
	}
	> .flare {
		@include opacity(70);
		@include border-radius(rem-calc(20));
		position: absolute;
		left: 50%;
		top: rem-calc(35);
		z-index: 10;
		background: #000;
		color: #fff;
		padding: rem-calc(10) rem-calc(20);
		transition: opacity 0.3s;

		&.init {
			@include opacity(0);
		}

		@media #{$medium-up} {
			top: rem-calc(15);
		}
	}
}

nav {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: rem-calc(120);
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	background: $top-background;
	transition: top 0.5s;

	@media #{$medium-up} {
		height: rem-calc(60);
	}

	.hidenav & {
		top: rem-calc(-120);
		@media #{$medium-up} {
			top: 0;
		}
	}

	.search-active & {
		.home {
			left: rem-calc(-80);
			@media #{$medium-up} {
				left: 0;
			}
		}
		.search {
			@include opacity(100);
			height: rem-calc(120);

			@media #{$medium-up} {
				height: rem-calc(60);
				left: rem-calc(80);
			}
		}
		.actions {
			.cart, .logout, .language-selector {
				@include opacity(0);
				top: rem-calc(-60);

				@media #{$medium-up} {
					top: rem-calc(-30);
				}
			}
			.searchbutton {
				color: $active-color;
			}
		}
	}

	.home {
		display: inline-block;
		width: rem-calc(150);
		height: rem-calc(120);
		color: $studio-color;
		position: relative;
		font-size: rem-calc(64);
		padding: rem-calc(24);
		cursor: pointer;

		transition: left 0.3s;
		left: 0;

		@media #{$medium-up} {
			width: rem-calc(75);
			height: rem-calc(60);
			font-size: rem-calc(32);
			padding: rem-calc(14);
		}
	}

	.search {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: rem-calc(200);
		overflow: hidden;

		transition: opacity 0.3s, height 0s;
		@include opacity(0);
		height: 0;

		@media #{$medium-up} {
			right: rem-calc(100);
		}

		input[type=text] {
			font-size: rem-calc(40);
			height: rem-calc(120);
			padding: rem-calc(40 30);
			border: 0;
			display: block;
			box-shadow: none;

			@media #{$medium-up} {
				font-size: rem-calc(20);
				height: rem-calc(60);
				padding: rem-calc(20 15);
			}

			&:focus {
				outline: 0;
				border: 0 none;
				background: #fff;
				box-shadow: none;
			}
		}
		input[type=submit] {
			display: none;
		}
	}

	.actions {
		position: absolute;
		right: 0;
		top: 0;

		> * {
			cursor: pointer;
		}
		.language-selector {
			position: relative;
			display: none;

			transition: opacity 0.3s, top 0.3s;
			@include opacity(100);
			top: 0;

			.icon-languages {
				font-size: rem-calc(32);
			}
			.text {
				font-size: rem-calc(16);
				position: relative;
				top: rem-calc(-6);
				margin-left: rem-calc(3);
			}
			.icon-dropdown {
				position: relative;
				top: rem-calc(-3);
				margin-left: rem-calc(3);
			}

			@media #{$medium-up} {
				display: inline-block;
			}
		}
		.logout {
			position: relative;
			display: inline-block;
			width: rem-calc(120);
			height: rem-calc(120);
			font-size: rem-calc(64);
			padding: rem-calc(24);
			vertical-align: top;

			transition: opacity 0.3s, top 0.3s;
			@include opacity(100);
			top: 0;

			@media #{$medium-up} {
				width: rem-calc(60);
				height: rem-calc(60);
				font-size: rem-calc(32);
				padding: rem-calc(14 12 10 12);
			}
		}
		.cart {
			position: relative;
			display: inline-block;
			width: rem-calc(150);
			height: rem-calc(120);
			font-size: rem-calc(64);
			padding: rem-calc(24);

			transition: opacity 0.3s, top 0.3s;
			@include opacity(100);
			top: 0;

			.count {
				display: block;
				position: absolute;
				color: $top-background;
				top: rem-calc(38);
				right: rem-calc(26);
				width: rem-calc(50);
				height: rem-calc(35);
				text-align: center;
				font-size: rem-calc(24);
				@media #{$medium-up} {
					top: rem-calc(19);
					right: rem-calc(12);
					width: rem-calc(25);
					height: rem-calc(18);
					font-size: rem-calc(14);
				}
			}

			@media #{$medium-up} {
				font-size: rem-calc(32);
				width: rem-calc(75);
				height: rem-calc(60);
				padding: rem-calc(14);
			}
		}
		.searchbutton {
			display: inline-block;
			-webkit-user-select: none;
			-moz-user-select: none;
			-khtml-user-select: none;
			-ms-user-select: none;
			width: rem-calc(120);
			height: rem-calc(120);
			font-size: rem-calc(64);
			padding: rem-calc(24);
			position: relative;
			top: 0;
			vertical-align: top;

			transition: right 0.3s, color 0.3s;
			right: 0;

			@media #{$medium-up} {
				width: rem-calc(60);
				height: rem-calc(60);
				padding: rem-calc(14);
				font-size: rem-calc(32);
			}
		}
		.analyticsbutton {
			display: inline-block;
			-webkit-user-select: none;
			-moz-user-select: none;
			-khtml-user-select: none;
			-ms-user-select: none;
			width: rem-calc(120);
			height: rem-calc(120);
			font-size: rem-calc(64);
			padding: rem-calc(24);
			position: relative;
			top: 0;
			vertical-align: top;

			transition: right 0.3s, color 0.3s;
			right: 0;

			@media #{$medium-up} {
				width: rem-calc(60);
				height: rem-calc(60);
				padding: rem-calc(14);
				font-size: rem-calc(32);
			}
		}
	}
}

#listing {
	background: $list-background;
	position: absolute;
	top: rem-calc(120);
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	margin-right: 0;
	transition: margin-right 0.3s 0.5s, top 0.5s;

	@media #{$medium-up} {
		top: rem-calc(60);
	}

	@media #{$large-up} {
		.cart-active & {
			margin-right: rem-calc(270);
		}
	}

	.hidenav & {
		top: 0;
		@media #{$medium-up} {
			top: rem-calc(60);
		}
	}

	.folder-utils {
		display: none;
		@media #{$medium-up} {
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			padding: rem-calc(20 25);

			div {
				cursor: pointer;
				float: right;
				font-size: rem-calc(16);
				padding: rem-calc(10 25);

				.search-active & {
					display: none;
				}

				.text {
					display: none;
				}

				&.select-all {
					color: $actions-color;
				}
				&.download {
					color: $background;
					background: $active-color;
					@include border-radius(rem-calc(30));

					.search-results & {
						display: none;
					}
				}
			}
		}
		@media #{$large-up} {
			div {
				.text {
					display: inline;
				}
			}
		}
	}

	.search-utils {
		display: none;
		min-height: rem-calc(86);
		color: $breadcrumb-color;
		background: $actions-background;
		z-index: 1;

		@media #{$medium-up} {
			min-height: rem-calc(43);
		}

		.search-active & {
			display: block;
		}

		.type {
			padding: rem-calc(30);
			position: relative;
			z-index: 1;

			@media #{$medium-up} {
				min-height: rem-calc(15);
				padding: rem-calc(15);
			}

			&.select-mode {
				padding: rem-calc(0);
				position: absolute;
				width: 100%;
				background: $background;
				color: $color;

				div {
					display: block;
					padding: rem-calc(30);
					cursor: pointer;

					@media #{$medium-up} {
						padding: rem-calc(15);
					}

					&::before {
						content: '' !important;
					}
					&:hover {
						color: $breadcrumb-color;
						background: $actions-background;
					}
					.search-results & {
						&.file, &.folder {
							display: none;
						}
					}
				}
			}

			div {
				font-size: rem-calc(26);
				display: none;

				@media #{$medium-up} {
					font-size: rem-calc(13);
				}

				&::before {
					content: 'Show: ';
				}
				.fi_FI &::before {
					content: 'Näytä: ';
				}
				.mode-all &.all {
					display: block;
				}
				.mode-file &.file {
					display: block;
				}
				.mode-image &.image {
					display: block;
				}
				.mode-video &.video {
					display: block;
				}
				.mode-folder &.folder {
					display: block;
				}
			}
			.icon-dropdown {
				font-size: rem-calc(32);
				position: absolute;
				right: rem-calc(30);
				top: rem-calc(30);

				@media #{$medium-up} {
					font-size: rem-calc(16);
					right: rem-calc(15);
					top: rem-calc(15);
				}
			}
		}
		.filter {
			display: none;
		}
	}
	.breadcrumb {
		.search-active & {
			display: none;
		}

		color: $breadcrumb-color;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: rem-calc(100);
		padding: rem-calc(30);
		font-size: rem-calc(32);
		overflow: hidden;

		a, a:active, a:visited {
			color: $breadcrumb-color;
		}

		.icon-home {
			vertical-align: baseline;
		}
		.icon-breadcrumb {
			font-size: rem-calc(24);
			vertical-align: middle;
		}

		@media #{$medium-up} {
			right: rem-calc(190);
			font-size: rem-calc(20);
			.icon-breadcrumb {
				font-size: rem-calc(20);
			}
		}
		@media #{$large-up} {
			right: rem-calc(380); /* leaves space for folder utils */
			font-size: rem-calc(16);
			.icon-breadcrumb {
				font-size: rem-calc(16);
			}
		}

	}
}

.filelisting {
	color: $list-color;
	padding: rem-calc(30);
	position: absolute;
	top: rem-calc(100);
	bottom: 0;
	right: 0;
	left: 0;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	@media #{$medium-up} {
		font-size: rem-calc(16);
		top: rem-calc(77);
	}

	li {
		display: none;
		padding-bottom: rem-calc(10);
	}
	.mode-all & {
		li {
			display: block;
		}
	}
	.mode-video & {
		.type-video {
			display: block;
		}
	}
	.mode-audio & {
		.type-audio {
			display: block;
		}
	}
	.mode-folder & {
		.type-folder {
			display: block;
		}
	}
	.mode-image & {
		.type-image {
			display: block;
		}
	}
	.mode-file & {
		li {
			display: block;
			&.type-folder {
				display: none;
			}
		}
	}

	.empty-listing {
		display: none;
	}
	&.no-results .empty-listing {
		display: block;
		text-align: center;
		padding: rem-calc(30);
		font-size: rem-calc(48);
		color: $breadcrumb-color;
	}

	.folder {
		width: 100%;
		margin: 0 auto;
		text-align: center;
		cursor: pointer;

		.thumb {
			background: $thumb-background url(#{$base-url}img/openstudio/folder.png) 50% 50% no-repeat;
			height: 150px;
			padding: 10%;
			-moz-box-sizing: content-box;
			box-sizing: content-box;
			background-size: 50% auto;
		}
		.iteminfo {
			position: relative;
			background: $actions-background;
			color: $actions-color;
			height: rem-calc(96);
			font-size: rem-calc(36);
			padding: rem-calc(22);
			width: 100%;
			@media #{$medium-up} {
				height: rem-calc(48);
				font-size: rem-calc(16);
				padding: rem-calc(11);
			}

			.ui-component-childstrip {
				height: rem-calc(96);
				overflow: hidden;
				text-align: center;
				@media #{$medium-up} {
					height: rem-calc(48);
				}

				&.with-items {
					text-align: left;
					margin: rem-calc(-22);
					@media #{$medium-up} {
						margin: rem-calc(-11);
					}
					.count {
						position: absolute;
						top: 0;
						right: 0;
						width: rem-calc(96);
						text-align: center;
						height: rem-calc(74);
						padding: rem-calc(22);
						overflow: hidden;
						@media #{$medium-up} {
							width: rem-calc(48);
							height: rem-calc(37);
							padding: rem-calc(11);
						}
					}
				}
				.stripimages {
					position: absolute;
					left: 0;
					right: rem-calc(96);
					height: rem-calc(96);
					overflow: hidden;

					@media #{$medium-up} {
						right: rem-calc(48);
						height: rem-calc(48);
					}
					.stripimage {
						display: inline-block;
						height: rem-calc(96);
						width: rem-calc(96);
						line-height: rem-calc(96);
						text-align: center;

						@media #{$medium-up} {
							height: rem-calc(48);
							width: rem-calc(48);
							line-height: rem-calc(48);
						}
						img {
							max-height: 100%;
							max-width: 100%;
							width: auto;
							height: auto;
							vertical-align: middle;
						}
					}
				}
			}
		}
		.name {
			font-size: rem-calc(24);
			padding: rem-calc(10);
			word-break: break-all;

			@media #{$medium-up} {
				font-size: rem-calc(16);
			}
		}
	}

	.file {
		width: 100%;
		margin: 0 auto;
		text-align: center;

		.thumb {
			position: relative;
			background: $thumb-background;
			padding: 10%;
			height: 150px;
			-moz-box-sizing: content-box;
			box-sizing: content-box;
			cursor: pointer;

			.helper {
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}
			img {
				vertical-align: baseline;
				max-width: 100%;
				max-height: 150px;
			}
			.loader {
				display: none;
				@include opacity(70);
				position: absolute;
				background: #fff url('#{$base-url}img/openstudio/imgloader.gif') 50% 50% no-repeat;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
		}
		&.loading {
			.thumb .loader {
				display: block;
			}
		}
		.actions {
			background: $actions-background;
			color: $actions-color;
			height: rem-calc(96);
			font-size: rem-calc(32);
			padding: rem-calc(24) 13%;

			@media #{$medium-up} {
				height: rem-calc(48);
				font-size: rem-calc(24);
				padding: rem-calc(7) 13%;
			}

			div {
				text-align: center;
				cursor: pointer;
			}
			.icon-basket-added {
				color: $studio-color;
			}
		}
		.name {
			font-size: rem-calc(24);
			padding: rem-calc(10);
			word-break: break-all;

			@media #{$medium-up} {
				font-size: rem-calc(16);
			}
		}
	}
}

#preview {
	background: $file-background;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-y: auto;
	overflow-x: visible;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	max-width: 100%;
	border:1px solid transparent;
	z-index: 1;

	transition: top 0.5s;
	top: 100%;

	&.active {
		top: rem-calc(120);

		.search-active & {
			top: rem-calc(206);
		}

		@media #{$medium-up} {
			top: rem-calc(60);

			.search-active & {
				top: rem-calc(103);
			}
		}

		.hidenav & {
			top: 0;

			@media #{$medium-up} {
				top: rem-calc(60);
				.search-active & {
					top: rem-calc(103);
				}
			}
		}
	}
}

@mixin previewbreadcrumb() {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: rem-calc(30);
	font-size: rem-calc(32);
	@include opacity(30);
	background: #000;
	color: #fff;
	z-index: 1;

	a {
		color: #fff;
	}

	@media #{$medium-up} {
		padding: rem-calc(15 20);
		font-size: rem-calc(15);
	}
}

@mixin previewactions() {
	padding: rem-calc(30 30 70);
	max-width: rem-calc(1000);
	margin: 0 auto;
	font-size: rem-calc(24);
	overflow: hidden;

	@media #{$medium-up} {
		font-size: rem-calc(16);
	}

	> span {
		font-size: rem-calc(58);
		margin: 0 rem-calc(15);
		float: left;
		@media #{$medium-up} {
			font-size: rem-calc(48);
		}
		&.cart-delimiter {
			float: right;
			color: $actions-color;
		}
	}
	> div {
		margin: 0 rem-calc(10);
		float: left;
		&.icon-previous, &.icon-next, &.icon-close-preview {
			font-size: rem-calc(64);
			cursor: pointer;
			&.disabled {
				color: $disabled-color;
				cursor: default;
			}
			&.loading {
				background: transparent url(#{$base-url}img/openstudio/nextprevloader.gif) 0 0 no-repeat;
				background-size: contain;
				width: rem-calc(48);
				height: rem-calc(48);

				&:before {
					content: "";
				}
			}

			@media #{$medium-up} {
				font-size: rem-calc(48);
			}
		}
		&.icon-delimiter {
			font-size: rem-calc(64);

			@media #{$medium-up} {
				font-size: rem-calc(48);
			}
		}

		@media #{$medium-up} {
			margin: rem-calc(0 5);
		}

		&.download {
			float: right;
			width: auto;
			padding: rem-calc(15 30);
			color: $background;
			background: $studio-color;
			@include border-radius(rem-calc(30));
			text-align: center;
			cursor: pointer;

			span {
				margin-right: rem-calc(5);
			}

			@media #{$medium-up} {
				width: auto;
				padding: rem-calc(15 30);
			}
		}
		&.add-to-cart {
			width: auto;
			padding: rem-calc(15 30);
			text-align: center;
			position: absolute;
			bottom: 0;
			right: rem-calc(0);
			cursor: pointer;
			color: $actions-color;

			&:hover {
				color: $color;
			}

			@media #{$medium-only} {
				margin-right: rem-calc(15);
			}
			@media #{$large-up} {
				float: right;
				width: auto;
				padding: rem-calc(12 15);
				position: relative;
			}

			span {
				margin-right: rem-calc(5);
			}
			.incart {
				display: none;
			}
			.notincart {
				display: block;
			}
		}
		&.conversions {
			position: relative;
			float: right;
			padding: rem-calc(15);
			color: $actions-color;
			&:hover {
				color: $color;
			}

			&.active {
			}

			.toggler {
				position: relative;
				cursor: pointer;
				padding-right: rem-calc(30);

				.text {
					max-width: rem-calc(200);
					overflow: hidden;
					display: inline-block;
					vertical-align: text-bottom;
					height: rem-calc(20);
				}
				.icon-dropdown {
					position: absolute;
					top: rem-calc(1);
					right: 0;
				}
			}
		}
	}
}

.ui-component-file {
	z-index: 0;
	&.small {
		.image {
			padding-top: rem-calc(46);
		}
	}
	&.incart {
		.incart {
			display: block !important;
		}
		.notincart {
			display: none !important;
		}
	}

	.clonedactions & {
		.actions {
			visibility: hidden;
		}
	}
	.clonedbreadcrumb & {
		.breadcrumb {
			visibility: hidden;
		}
	}

	.breadcrumb {
		@include previewbreadcrumb();
	}

	.image {
		position: relative;
		overflow: hidden;
		background: $list-background;
		z-index: 1;

		img, > .flowplayer {
			position: relative;
			top: 0;
			left: 0;
			display: block;
			margin: 0 auto;
			max-width: 100%;
		}
		.flowplayer .fp-time {
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.pdf {
			position: relative;
			margin-top: rem-calc(46);
			width: 100%;
			height: rem-calc(600);
			object, embed {
				margin: 0 auto;
				width: 100%;
				height: 100%;
			}
			@include respond-to(almost) {
				display: none;
			}
		}
		.mobilepdf {
			display: none;
			@include respond-to(almost) {
				position: relative;
				top: 0;
				left: 0;
				display: block;
				margin: 0 auto;
				max-width: 100%;
			}
		}
	}
	.details {
		background: $file-background;
		z-index: 0;
	}
	.actions {
		position: relative;
		@include previewactions();
	}
	.info {
		padding: rem-calc(30);
		max-width: rem-calc(1000);
		margin: 0 auto;
		overflow: auto;
		-webkit-overflow-scrolling: touch;

		.item {
			float: left;
			width: 100%;

			@media #{$medium-only} {
				width: 50%;
			}

			@media #{$large-up} {
				width: 33%;
			}

			h3 {
				font-size: rem-calc(28);
				color: $info-header;
				text-transform: uppercase;

				@media #{$medium-up} {
					font-size: rem-calc(14);
					font-weight: bold;
				}
			}
			p {
				font-size: rem-calc(48);

				@media #{$medium-up} {
					font-size: rem-calc(24);
				}
			}

			&.name {
				width: 100%;
			}
			&.description, &.stage {
				width: 100%;

				p {
					font-size: rem-calc(28);
					@media #{$medium-up} {
						font-size: rem-calc(14);
					}
				}
			}
		}
	}

	.analytics {
		position: relative;
		padding: rem-calc(30);
		max-width: rem-calc(1000);
		margin: 0 auto;
		overflow: auto;
		-webkit-overflow-scrolling: touch;

		h3 {
			font-size: rem-calc(28);
			color: $info-header;
			text-transform: uppercase;

			@media #{$medium-up} {
				font-size: rem-calc(14);
				font-weight: bold;
			}
		}
		h4 {
			font-size: rem-calc(32);
			margin-bottom: rem-calc(10);
			font-weight: 600;

			@media #{$medium-up} {
				font-size: rem-calc(16);
			}
		}
		.date {
			> div {
				display: inline-block;
				margin-left: rem-calc(20);
				&:first-child {
					margin-left: 0;
				}
			}
			input {
				display: inline-block;
				width: rem-calc(150);
			}
			label {
				display: inline-block;
			}
			.changedate {
				display: inline-block;
				cursor: pointer;
				color: $cart-color;
				background: $cart-button-background;
				text-align: center;
				font-size: rem-calc(14);
				padding: rem-calc(10 25);
				@include border-radius(rem-calc(30));
			}
		}
		.stats {
			overflow: hidden;
			padding: rem-calc(15) 0 rem-calc(40);

			> div {
				border: rem-calc(1) solid rgba(0, 0, 0, 0.1);
				-webkit-border-radius: rem-calc(3);
				-moz-border-radius: rem-calc(3);
				border-radius: rem-calc(3);
				float: left;
				margin: 0 2% 0 0;
				padding: rem-calc(30);
				text-align: center;
				width: 32%;

				&:last-child {
					margin-right: 0;
				}

				span {
					font-size: rem-calc(18);
					font-weight: 400;
					display: block;
					line-height: rem-calc(27);
				}
				b {
					font-size: rem-calc(40);
					display: block;
					line-height: rem-calc(60);
					font-weight: 700;
				}
			}
		}
		.graph {
			padding: rem-calc(0 0 20);
			.graphcontainer {
				height: rem-calc(200);
			}
		}
		.downloaders {
			clear: left;
			padding-top: rem-calc(20);
			table {
				width: 100%;

				font-size: rem-calc(14);
				td, th {
					text-align: left;
					padding: rem-calc(5 10);
					&:first-child {
						padding-left: 0;
					}
				}
				td {
					border-left: rem-calc(1) solid $border-color;
					&:first-child {
						border-left: 0 none;
					}
				}
			}
		}

	}
}

.ui-component-dropdownlist {
	display: none;
}

body > .ui-component-dropdownlist {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	border: 1px solid $actions-color;
	background: $background;
	color: $color;
	z-index: 10020;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	font-size: rem-calc(32);

	@media #{$medium-up} {
		font-size: rem-calc(16);
	}

	> div {
		cursor: pointer;
		padding: rem-calc(20 40);
		&:hover {
			color: $breadcrumb-color;
			background: $actions-background;
		}
		@media #{$medium-up} {
			padding: rem-calc(10 20);
		}
	}
}

#analyticspanel {
	position: absolute;
	background: #fff;
	overflow: auto;
	top: rem-calc(0);
	bottom: 0;
	width: 100%;
	padding: rem-calc(30);
	display: none;

	.analytics-active & {
		display: block;
	}
	@media #{$medium-up} {
		top: rem-calc(61);
	}

	h3 {
		clear: both;
	}

	.scope {
		position: absolute;
		right: rem-calc(30);
		top: rem-calc(50);

		div {
			display: inline-block;
			cursor: pointer;
			position: relative;

			&:hover {
				color: $hover-color;
			}

			&.active {
				font-weight: 600;
				cursor: default;
				&:hover {
					color: $color;
				}
			}
			.loader {
				display: none;
				@include opacity(70);
				position: absolute;
				background: #fff url('#{$base-url}img/openstudio/imgloadersmallest.gif') 50% 50% no-repeat;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
			&.loading {
				.loader {
					display: block;
				}
			}
		}
	}

	.stats {
		padding: rem-calc(15) rem-calc(40) rem-calc(40);
		width: 50%;
		> div {
			border: rem-calc(1) solid rgba(0, 0, 0, 0.1);
			-webkit-border-radius: rem-calc(3);
			-moz-border-radius: rem-calc(3);
			border-radius: rem-calc(3);
			float: left;
			margin: 0 2% rem-calc(15) 0;
			padding: rem-calc(30);
			text-align: center;
			width: 48%;

			span {
				font-size: rem-calc(36);
				line-height: rem-calc(54);
				font-weight: 400;
				display: block;
			}
			b {
				display: block;
				font-size: rem-calc(80);
				line-height: rem-calc(120);
				font-weight: 700;
			}
			em {
				font-size: rem-calc(48);
				padding-left: rem-calc(10);
				border-left: rem-calc(1) solid $border-color;
			}
			@media #{$medium-up} {
				span {
					font-size: rem-calc(18);
					line-height: rem-calc(27);
				}
				b {
					font-size: rem-calc(40);
					line-height: rem-calc(60);
				}
				em {
					font-size: rem-calc(24);
				}
			}
		}
	}
	.pie {
		.piecontainer {
			height: rem-calc(300);
			position: absolute;
			right: rem-calc(40);
			text-align: center;
			top: rem-calc(170);
			width: 50%;
		}
	}
	.date {
		> div {
			display: inline-block;
			margin-left: rem-calc(20);
			&:first-child {
				margin-left: 0;
			}
		}
		input {
			display: inline-block;
			width: rem-calc(150);
		}
		label {
			display: inline-block;
		}
		.changedate {
			display: inline-block;
			cursor: pointer;
			color: $cart-color;
			background: $cart-button-background;
			text-align: center;
			font-size: rem-calc(14);
			padding: rem-calc(10 25);
			@include border-radius(rem-calc(30));
		}
	}
	.topfiles {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		.file {
			cursor: pointer;
			position: relative;
			min-width: 33%;
			&:hover {
				background: #eee;
			}
			.thumb {
				width: rem-calc(80);
				text-align: center;
				line-height: rem-calc(80);
				vertical-align: middle;
				display: block;
				img {
					display: inline-block;
					border: 3px solid indianred;
					max-width: rem-calc(80);
					max-height: rem-calc(80);
				}
			}
			.text {
				font-size: rem-calc(28);
				line-height: rem-calc(36);
				display: block;
				position: absolute;
				left: rem-calc(100);
				top: rem-calc(10);

				@media #{$medium-up} {
					font-size: rem-calc(14);
					line-height: rem-calc(18);
				}
			}
			.loader {
				display: none;
				@include opacity(70);
				position: absolute;
				background: #fff url('#{$base-url}img/openstudio/imgloadersmall.gif') 50% 50% no-repeat;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
			&.loading {
				.loader {
					display: block;
				}
			}
		}
	}
}

#cart {
	background: $cart-background;
	color: $cart-color;
	$margin: rem-calc(30);
	$margin-left: rem-calc(50);

	position: absolute;
	width: 0;
	top: rem-calc(0);
	bottom: 0;
	z-index: 1010;
	padding-top: rem-calc(30);
	overflow: hidden;

	transition: right 0.5s, width 0s 0.5s;
	right: -100%;

	@media #{$medium-up} {
		top: rem-calc(61);
		$margin: rem-calc(25);
		$margin-left: rem-calc(30);
	}

	&.select-shown {
		.items {
			bottom: rem-calc(340);

			@media #{$medium-up} {
				bottom: rem-calc(170);
			}
		}
	}

	h2 {
		position: relative;
		color: $cart-dim-color;
		text-transform: uppercase;
		margin: $margin $margin $margin $margin-left;
		font-size: rem-calc(36);
		border-bottom: 1px solid $cart-dim-color;
		margin-top: 0;

		@media #{$medium-up} {
			font-size: rem-calc(18);
		}

		span {
			display: block;
			position: absolute;
			right: rem-calc(-10);
			top: rem-calc(0);
			font-size: rem-calc(32);
			padding: rem-calc(10);
			cursor: pointer;

			&.clear {
				top: rem-calc(-3);
				right: rem-calc(42);
			}

			@media #{$medium-up} {
				top: rem-calc(0);
				right: rem-calc(0);
				font-size: rem-calc(16);
				padding: rem-calc(5);
				&.clear {
					right: rem-calc(21);
				}
			}

			&:hover {
				color: $cart-color;
			}
		}
	}

	.items {
		margin-left: $margin-left;
		margin-right: $margin;
		position: absolute;
		top: rem-calc(140);
		bottom: rem-calc(160);
		left: 0;
		right: 0;
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
		padding-bottom: rem-calc(20);

		@media #{$medium-up} {
			top: rem-calc(70);
			bottom: rem-calc(80);
		}

		ul {
			margin: 0;
			li {
				position: relative;
				padding: 0;
				min-height: rem-calc(120);
				margin-bottom: rem-calc(20);

				@media #{$medium-up} {
					min-height: rem-calc(60);
					margin-bottom: rem-calc(10);
				}

				&.file-active {
					background: $cart-active-item;
				}
				.thumb {
					position: absolute;
					top:0;
					left: 0;
					img {
						max-width: rem-calc(120);
						max-height: rem-calc(120);

						@media #{$medium-up} {
							max-width: rem-calc(60);
							max-height: rem-calc(60);
						}
					}
				}
				.name {
					position: relative;
					margin: rem-calc(0 0 0 135);
					padding-right: rem-calc(58);
					font-size: rem-calc(32);

					.remove {
						position: absolute;
						right: 0;
						top: rem-calc(-5);
						padding: rem-calc(5 0 5 5);
						color: $cart-actions;
						cursor: pointer;
						font-size: rem-calc(48);
						.remove-text {
							display: none;
						}
					}

					@media #{$medium-up} {
						margin: rem-calc(0 0 0 65);
						padding-right: 0;
						font-size: rem-calc(12);
						.remove {
							position: static;
							font-size: rem-calc(12);
							.remove-text {
								display: inline;
							}
						}
					}
				}
			}
		}
	}

	footer {
		background: $cart-background;
		color: $cart-color;
		position: absolute;
		width: 100%;
		bottom: 0;

		h3 {
			padding: rem-calc(20 40);
			margin: 0;
			border-bottom: rem-calc(1) solid $cart-line-color;

			@media #{$medium-up} {
				padding: rem-calc(10 20);
			}
		}

		h3, .toggler {
			color: $cart-dim-color;
			font-size: rem-calc(24);

			@media #{$medium-up} {
				font-size: rem-calc(12);
			}
		}

		.toggler {
			cursor: pointer;
			position: relative;
			padding: rem-calc(40);

			@media #{$medium-up} {
				padding: rem-calc(20);
			}

			.icon-dropdown {
				position: absolute;
				top: rem-calc(41);
				right: rem-calc(40);

				@media #{$medium-up} {
					top: rem-calc(20);
					right: rem-calc(20);
				}
			}
		}

		.select {
			display: none;
			transition: height 0.5s;

			&.visible {
				display: block;
				height: 0;
				overflow: hidden;

				&.shown {
					height: rem-calc(180);

					@media #{$medium-up} {
						height: rem-calc(90);
					}
				}
			}

			.conversions {
				height: rem-calc(100);

				@media #{$medium-up} {
					height: rem-calc(50);
				}
			}
		}
		.download {
			cursor: pointer;
			background: $cart-button-background;
			text-align: center;
			font-size: rem-calc(36);
			padding-top: rem-calc(30);
			padding-bottom: rem-calc(30);

			@media #{$medium-up} {
				font-size: rem-calc(18);
			}

			.icon-download {
				position: relative;
				top: rem-calc(3);
				margin-right: rem-calc(5);
			}
		}
	}

	&.active {
		right: 0;
		width: 95%;
		transition: right 0.5s, width 0s;

		@media #{$large-up} {
			width: rem-calc(270);
		}
	}
}

#passwordform {
	max-width: rem-calc(800);
	margin: rem-calc(160) auto rem-calc(30);
	text-align: center;

	@media #{$medium-up} {
		margin-top: rem-calc(100);
	}

	input {
		width: rem-calc(250);
		margin: rem-calc(15) auto;
	}

	input[type=checkbox] {
		width: auto;
	}

	input[type=submit] {
		color: $background;
		background: $active-color;
		@include border-radius(rem-calc(30));
		width: rem-calc(200);
	}
}

.previewbreadcrumb-container {
	position: absolute;
	left: 0;
	right: 0;
	top: rem-calc(120);
	z-index: -1;
	visibility: hidden;
	@include opacity(0);
	transition: visibility 0s 0.5s, z-index 0s 0.5s, opacity 0.5s linear;
	.hidenav & {
		top: 0;
	}

	.clonedbreadcrumb & {
		display: block;
		z-index: 1000;
		visibility: visible;
		@include opacity(100);
		transition: opacity 0.5s linear, top 0.5s;
	}

	.search-active & {
		display: none;
	}

	@media #{$medium-up} {
		top: rem-calc(60);
		.hidenav & {
			top: rem-calc(60);
		}
	}
}
#previewbreadcrumb-clone {
	@include previewbreadcrumb();
}

.previewactions-container {
	position: absolute;
	left: 0;
	bottom: rem-calc(-110);
	right: 0;
	z-index: -1;
	visibility: hidden;
	background: transparent url('#{$base-url}img/openstudio/white-bg-70.png') repeat;
	transition: bottom 0.5s;
	height: rem-calc(150);

	.clonedactions & {
		visibility: visible;
		z-index: 1000;
		bottom: 0;
	}

	.search-active & {
		display: none;
	}

	@media #{$medium-up} {
		height: auto;
	}
}
#previewactions-clone {
	@include previewactions();
	position: relative;
	padding-bottom: rem-calc(30);

	> div.conversions {
		@media #{$medium-only} {
			display: none !important;
		}
	}

	> div.add-to-cart {
		@media #{$medium-up} {
			float: right;
			margin-right: rem-calc(5);
			position: static;
		}
		@media #{$small-only} {
			bottom: rem-calc(-15);
			right: rem-calc(15);
		}
	}

	&.incart {
		.incart {
			display: block !important;
		}
		.notincart {
			display: none !important;
		}
	}
}

.ui-component-resultloader {
	text-align: center;
	@include opacity(100);
	padding: rem-calc(30);
	font-size: rem-calc(32);
	height: rem-calc(64);
	color: $actions-color;
	span {
		display: none;
	}
	&.nomore .nomore, &.loading .loading {
		display: inline;
	}
	&.nomore {
		@include opacity(0);
		height: 0;
		padding: 0;
		transition: height 1s, padding 1s, opacity 1s;
	}
}

.ui-area-loading {

	.ui-area-loading-loader {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 2500;
		@include opacity(70);
		background: #fff url(#{$base-url}img/openstudio/imgloader.gif) 50% 50% no-repeat;
	}
}
