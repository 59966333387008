@charset "UTF-8";
/*
These are available:
@include respond-to(retina) {
	rules here will apply only when device pixel ratio is over 2
}
@include respond-to(dense) {
	rules here will apply only when device pixel ratio is over 3
}

@media #{$small-up} { }
@media #{$small-only} { }

@media #{$medium-up} { }
@media #{$medium-only} { }

@media #{$large-up} { }
@media #{$large-only} { }

@media #{$xlarge-up} { }
@media #{$xlarge-only} { }

@media #{$xxlarge-up} { }
@media #{$xxlarge-only} { }
*/

$background: #ffffff;
$color: #242424;
$hover-color: #666;
$disabled-color: #bbbbbb;
$border-color: #ddd;

$studio-color: #6fba05;

$top-background: $background;

$list-background: #f2f2f2;
$list-color: #555555;

$thumb-background: $background;

$actions-background: #e4e4e3;

$file-background: $background;

$actions-color: #777777;
$active-color: #019cde;
$breadcrumb-color: #939393;
$info-header: #aaaaaa;

$cart-background: $color;
$cart-color: $background;
$cart-actions: $actions-color;
$cart-dim-color: #bbbbbb;
$cart-button-background: #74bd0d;
$cart-line-color: #4d4d4d;
$cart-active-item: #505050;

.icon-studio:before {
	content: "\e614";
}
