@charset "UTF-8";

@import "foundation/normalize";
@import "foundation/foundation";
@import "openstudio-fonts";
//@import "./vendor/codemirror/codemirror"; This is not included in the basic openstudio.css so do we need it in minimized?. Feel free to comment out, if you have a good reason
@import "flowplayer/minimalist";

@mixin respond-to($media) {

	@if $media == dense {
		@media only screen and (-webkit-min-device-pixel-ratio: 3),
		only screen and (min-resolution: 3dppx),
		only screen and (min-resolution: 288dpi) { @content; }
	}
	@else if $media == retina {
		@media only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (min-resolution: 2dppx),
		only screen and (min-resolution: 192dpi) { @content; }
	}
	@else if $media == almost {
		@media only screen and (-webkit-min-device-pixel-ratio: 1.1),
		only screen and (min-resolution: 1.1dppx),
		only screen and (min-resolution: 105dpi) { @content; }
	}
}

@import "openstudio-colors.scss";