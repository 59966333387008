$flowplayer-img-url: $base-url + 'img/flowplayer/' !default;

.flowplayer{position:relative;width:100%;background-size:contain;background-repeat:no-repeat;background-position:center center;display:inline-block;}
.flowplayer *{font-weight:inherit;font-family:inherit;font-style:inherit;text-decoration:inherit;font-size:100%;padding:0;border:0;margin:0;list-style-type:none}
.flowplayer a:focus{outline:0}
.flowplayer video{width:100%}
.flowplayer.is-ipad video{-webkit-transform:translateX(-2048px);}
.is-ready.flowplayer.is-ipad video{-webkit-transform:translateX(0)}
.flowplayer .fp-engine,.flowplayer .fp-ui,.flowplayer .fp-message{position:absolute;top:0;left:0;width:100%;height:100%;cursor:pointer;z-index:1}
.flowplayer .fp-message{display:none;text-align:center;padding-top:5%;cursor:default;}
.flowplayer .fp-message h2{font-size:120%;margin-bottom:1em}
.flowplayer .fp-message p{color:#666;font-size:95%}
.flowplayer .fp-controls{position:absolute;bottom:0;width:100%;}
.no-background.flowplayer .fp-controls{background-color:transparent !important;background-image:-moz-linear-gradient(transparent,transparent) !important;background-image:-webkit-gradient(linear,0 0,0 100%,from(transparent),to(transparent)) !important}
.is-fullscreen.flowplayer .fp-controls{bottom:3px}
.is-mouseover.flowplayer .fp-controls{bottom:0}
.flowplayer .fp-waiting{display:none;margin:19% auto;text-align:center;}
.flowplayer .fp-waiting *{-webkit-box-shadow:0 0 5px #333;-moz-box-shadow:0 0 5px #333;box-shadow:0 0 5px #333}
.flowplayer .fp-waiting em{width:1em;height:1em;-webkit-border-radius:1em;-moz-border-radius:1em;border-radius:1em;background-color:rgba(255,255,255,0.8);display:inline-block;-webkit-animation:pulse .6s infinite;-moz-animation:pulse .6s infinite;animation:pulse .6s infinite;margin:.3em;opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);}
.flowplayer .fp-waiting em:nth-child(1){-webkit-animation-delay:.3s;-moz-animation-delay:.3s;animation-delay:.3s}
.flowplayer .fp-waiting em:nth-child(2){-webkit-animation-delay:.45s;-moz-animation-delay:.45s;animation-delay:.45s}
.flowplayer .fp-waiting em:nth-child(3){-webkit-animation-delay:.6s;-moz-animation-delay:.6s;animation-delay:.6s}
.flowplayer .fp-waiting p{color:#ccc;font-weight:bold}
.flowplayer .fp-speed{font-size:30px;background-color:#333;background-color:rgba(51,51,51,0.8);color:#eee;margin:0 auto;text-align:center;width:120px;padding:.1em 0 0;opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition:opacity .5s;-moz-transition:opacity .5s;transition:opacity .5s;}
.flowplayer .fp-speed.fp-hilite{opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer .fp-help{position:absolute;top:0;left:-9999em;z-index:100;background-color:#333;background-color:rgba(51,51,51,0.9);width:100%;height:100%;opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition:opacity .2s;-moz-transition:opacity .2s;transition:opacity .2s;text-align:center;}
.is-help.flowplayer .fp-help{left:0;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer .fp-help .fp-help-section{margin:3%;direction:ltr}
.flowplayer .fp-help .fp-help-basics{margin-top:6%}
.flowplayer .fp-help p{color:#eee;margin:.5em 0;font-size:14px;line-height:1.5;display:inline-block;margin:1% 2%}
.flowplayer .fp-help em{background:#eee;-webkit-border-radius:.3em;-moz-border-radius:.3em;border-radius:.3em;margin-right:.4em;padding:.3em .6em;color:#333}
.flowplayer .fp-help small{font-size:90%;color:#aaa}
.flowplayer .fp-help .fp-close{display:block}
@media (max-width: 600px){.flowplayer .fp-help p{font-size:9px}
}.flowplayer .fp-subtitle{position:absolute;bottom:40px;left:-99999em;z-index:10;text-align:center;width:100%;opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition:opacity .3s;-moz-transition:opacity .3s;transition:opacity .3s;}
.flowplayer .fp-subtitle p{display:inline;background-color:#333;background-color:rgba(51,51,51,0.9);color:#eee;padding:.1em .4em;font-size:16px;line-height:1.6;}
.flowplayer .fp-subtitle p:after{content:'';clear:both}
.flowplayer .fp-subtitle.fp-active{left:0;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer .fp-fullscreen,.flowplayer .fp-unload,.flowplayer .fp-mute,.flowplayer .fp-embed,.flowplayer .fp-close,.flowplayer .fp-play{background-image:url(#{$flowplayer-img-url}white.png);background-size:37px 300px;}
.is-rtl.flowplayer .fp-fullscreen,.is-rtl.flowplayer .fp-unload,.is-rtl.flowplayer .fp-mute,.is-rtl.flowplayer .fp-embed,.is-rtl.flowplayer .fp-close,.is-rtl.flowplayer .fp-play{background-image:url(#{$flowplayer-img-url}white_rtl.png)}
.color-light.flowplayer .fp-fullscreen,.color-light.flowplayer .fp-unload,.color-light.flowplayer .fp-mute,.color-light.flowplayer .fp-embed,.color-light.flowplayer .fp-close,.color-light.flowplayer .fp-play{background-image:url(#{$flowplayer-img-url}black.png);}
.is-rtl.color-light.flowplayer .fp-fullscreen,.is-rtl.color-light.flowplayer .fp-unload,.is-rtl.color-light.flowplayer .fp-mute,.is-rtl.color-light.flowplayer .fp-embed,.is-rtl.color-light.flowplayer .fp-close,.is-rtl.color-light.flowplayer .fp-play{background-image:url(#{$flowplayer-img-url}black_rtl.png)}
@media (-webkit-min-device-pixel-ratio: 2){.color-light.flowplayer .fp-fullscreen,.color-light.flowplayer .fp-unload,.color-light.flowplayer .fp-mute,.color-light.flowplayer .fp-embed,.color-light.flowplayer .fp-close,.color-light.flowplayer .fp-play{background-image:url(#{$flowplayer-img-url}black@x2.png)}
.is-rtl.color-light.flowplayer .fp-fullscreen,.is-rtl.color-light.flowplayer .fp-unload,.is-rtl.color-light.flowplayer .fp-mute,.is-rtl.color-light.flowplayer .fp-embed,.is-rtl.color-light.flowplayer .fp-close,.is-rtl.color-light.flowplayer .fp-play{background-image:url(#{$flowplayer-img-url}black_rtl@x2.png)}
}@media (-webkit-min-device-pixel-ratio: 2){.flowplayer .fp-fullscreen,.flowplayer .fp-unload,.flowplayer .fp-mute,.flowplayer .fp-embed,.flowplayer .fp-close,.flowplayer .fp-play{background-image:url(#{$flowplayer-img-url}white@x2.png)}
.is-rtl.flowplayer .fp-fullscreen,.is-rtl.flowplayer .fp-unload,.is-rtl.flowplayer .fp-mute,.is-rtl.flowplayer .fp-embed,.is-rtl.flowplayer .fp-close,.is-rtl.flowplayer .fp-play{background-image:url(#{$flowplayer-img-url}white_rtl@x2.png)}
}.is-splash.flowplayer .fp-ui,.is-paused.flowplayer .fp-ui{background:url(#{$flowplayer-img-url}play_white.png) center no-repeat;background-size:12%;}
.is-rtl.is-splash.flowplayer .fp-ui,.is-rtl.is-paused.flowplayer .fp-ui{background:url(#{$flowplayer-img-url}play_white_rtl.png) center no-repeat;background-size:12%}
@media (-webkit-min-device-pixel-ratio: 2){.is-splash.flowplayer .fp-ui,.is-paused.flowplayer .fp-ui{background:url(#{$flowplayer-img-url}play_white@x2.png) center no-repeat;background-size:12%}
.is-rtl.is-splash.flowplayer .fp-ui,.is-rtl.is-paused.flowplayer .fp-ui{background:url(#{$flowplayer-img-url}play_white_rtl@x2.png) center no-repeat;background-size:12%}
}.color-light.is-splash.flowplayer .fp-ui,.color-light.is-paused.flowplayer .fp-ui{background-image:url(#{$flowplayer-img-url}play_black.png);}
.is-rtl.color-light.is-splash.flowplayer .fp-ui,.is-rtl.color-light.is-paused.flowplayer .fp-ui{background-image:url(#{$flowplayer-img-url}play_black_rtl.png)}
@media (-webkit-min-device-pixel-ratio: 2){.color-light.is-splash.flowplayer .fp-ui,.color-light.is-paused.flowplayer .fp-ui{background-image:url(#{$flowplayer-img-url}play_black@x2.png);}
.is-rtl.color-light.is-splash.flowplayer .fp-ui,.is-rtl.color-light.is-paused.flowplayer .fp-ui{background-image:url(#{$flowplayer-img-url}play_black_rtl@x2.png)}
}.is-fullscreen.flowplayer .fp-ui{background-size:auto}
.is-seeking.flowplayer .fp-ui,.is-loading.flowplayer .fp-ui{background-image:none}
.flowplayer .fp-logo{position:absolute;top:auto;left:15px;bottom:30px;cursor:pointer;display:none;z-index:100;}
.flowplayer .fp-logo img{width:100%}
.is-embedded.flowplayer .fp-logo{display:block}
.fixed-controls.flowplayer .fp-logo{bottom:15px}
.flowplayer .fp-fullscreen,.flowplayer .fp-unload,.flowplayer .fp-close{position:absolute;top:5px;left:auto;right:5px;display:block;width:30px;height:23px;background-position:12px -197px;cursor:pointer;}
.is-rtl.flowplayer .fp-fullscreen,.is-rtl.flowplayer .fp-unload,.is-rtl.flowplayer .fp-close{right:auto;left:5px;background-position:18px -197px}
.flowplayer .fp-unload,.flowplayer .fp-close{background-position:14px -175px;display:none;}
.is-rtl.flowplayer .fp-unload,.is-rtl.flowplayer .fp-close{background-position:14px -175px}
.flowplayer .fp-play{display:none;width:27px;height:20px;background-position:9px -24px;position:absolute;bottom:0;left:0;}
.is-rtl.flowplayer .fp-play{background-position:18px -24px;left:auto;right:0}
.play-button.flowplayer .fp-play{display:block}
.is-paused.flowplayer .fp-play{background-position:9px 7px;}
.is-rtl.is-paused.flowplayer .fp-play{background-position:18px 7px}
.flowplayer.is-ready.is-closeable .fp-unload{display:block}
.flowplayer.is-ready.is-closeable .fp-fullscreen{display:none}
.flowplayer.is-fullscreen .fp-fullscreen{background-position:10px -217px;display:block !important;}
.is-rtl.flowplayer.is-fullscreen .fp-fullscreen{background-position:21px -217px}
.flowplayer.is-fullscreen .fp-unload,.flowplayer.is-fullscreen .fp-close{display:none !important}
.flowplayer .fp-timeline{height:3px;position:relative;overflow:hidden;top:5px;height:10px;margin:0 150px 0 45px;}
.no-volume.flowplayer .fp-timeline{margin-right:60px}
.no-mute.flowplayer .fp-timeline{margin-right:140px}
.no-mute.no-volume.flowplayer .fp-timeline{margin-right:45px}
.play-button.flowplayer .fp-timeline{margin-left:67px}
.is-rtl.flowplayer .fp-timeline{margin:0 45px 0 150px;}
.no-volume.is-rtl.flowplayer .fp-timeline{margin-left:60px}
.no-mute.is-rtl.flowplayer .fp-timeline{margin-left:140px}
.no-mute.no-volume.is-rtl.flowplayer .fp-timeline{margin-left:45px}
.play-button.is-rtl.flowplayer .fp-timeline{margin-right:67px}
.is-long.flowplayer .fp-timeline{margin:0 180px 0 75px;}
.no-volume.is-long.flowplayer .fp-timeline{margin-right:90px}
.no-mute.is-long.flowplayer .fp-timeline{margin-right:75px}
.play-button.is-long.flowplayer .fp-timeline{margin-left:97px}
.is-rtl.is-long.flowplayer .fp-timeline{margin:75px 0 180px 0;}
.no-volume.is-rtl.is-long.flowplayer .fp-timeline{margin-left:90px}
.no-mute.is-rtl.is-long.flowplayer .fp-timeline{margin-left:75px}
.play-button.is-rtl.is-long.flowplayer .fp-timeline{margin-left:97px}
.aside-time.flowplayer .fp-timeline,.no-time.flowplayer .fp-timeline{margin:0 110px 0 5px}
.aside-time.no-volume.flowplayer .fp-timeline,.no-time.no-volume.flowplayer .fp-timeline{margin-right:20px}
.aside-time.no-mute.flowplayer .fp-timeline,.no-time.no-mute.flowplayer .fp-timeline{margin-right:5px}
.play-button.no-time.flowplayer .fp-timeline,.play-button.aside-time.flowplayer .fp-timeline{margin-left:27px}
.is-rtl.aside-time.flowplayer .fp-timeline,.is-rtl.no-time.flowplayer .fp-timeline{margin:0 5px 0 110px}
.is-rtl.aside-time.no-volume.flowplayer .fp-timeline,.is-rtl.no-time.no-volume.flowplayer .fp-timeline{margin-left:20px}
.is-rtl.aside-time.no-mute.flowplayer .fp-timeline,.is-rtl.no-time.no-mute.flowplayer .fp-timeline{margin-left:5px}
.is-rtl.play-button.no-time.flowplayer .fp-timeline,.is-rtl.play-button.aside-time.flowplayer .fp-timeline{margin-right:27px}
.flowplayer .fp-buffer,.flowplayer .fp-progress{position:absolute;top:0;left:auto;height:100%;cursor:col-resize}
.flowplayer .fp-buffer{-webkit-transition:width .25s linear;-moz-transition:width .25s linear;transition:width .25s linear}
.flowplayer.is-touch .fp-timeline{overflow:visible}
.flowplayer.is-touch .fp-progress{-webkit-transition:width .2s linear;-moz-transition:width .2s linear;transition:width .2s linear}
.flowplayer.is-touch .fp-timeline.is-dragging .fp-progress{-webkit-transition:right .1s linear,border .1s linear,top .1s linear,left .1s linear;-moz-transition:right .1s linear,border .1s linear,top .1s linear,left .1s linear;transition:right .1s linear,border .1s linear,top .1s linear,left .1s linear}
.flowplayer.is-touch.is-mouseover .fp-progress:after,.flowplayer.is-touch.is-mouseover .fp-progress:before{content:'';display:block;-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px;position:absolute;right:-5px}
.flowplayer.is-touch.is-rtl.is-mouseover .fp-progress:after,.flowplayer.is-touch.is-rtl.is-mouseover .fp-progress:before{right:auto;left:-5px}
.flowplayer.is-touch.is-rtl.is-mouseover .fp-progress:after{left:-10px;-webkit-box-shadow:-1px 0 4px rgba(0,0,0,0.5);-moz-box-shadow:-1px 0 4px rgba(0,0,0,0.5);box-shadow:-1px 0 4px rgba(0,0,0,0.5)}
.flowplayer.is-touch.is-mouseover .fp-progress:before{width:10px;height:10px}
.flowplayer.is-touch.is-mouseover .fp-progress:after{height:10px;width:10px;top:-5px;right:-10px;border:5px solid rgba(255,255,255,0.65);-webkit-box-shadow:1px 0 4px rgba(0,0,0,0.5);-moz-box-shadow:1px 0 4px rgba(0,0,0,0.5);box-shadow:1px 0 4px rgba(0,0,0,0.5)}
.flowplayer.is-touch.is-mouseover .fp-timeline.is-dragging .fp-progress:after{border:10px solid #fff;-webkit-border-radius:20px;-moz-border-radius:20px;border-radius:20px;-webkit-transition:inherit;-moz-transition:inherit;transition:inherit;top:-10px;right:-15px}
.flowplayer.is-touch.is-rtl.is-mouseover .fp-timeline.is-dragging .fp-progress:after{left:-15px;right:auto;border:10px solid #fff}
.flowplayer .fp-volume{position:absolute;top:7.5px;right:5px;}
.is-rtl.flowplayer .fp-volume{right:auto;left:5px}
.flowplayer .fp-mute{position:relative;width:10px;height:15px;float:left;top:-5px;cursor:pointer;background-position:-2px -99px;}
.is-rtl.flowplayer .fp-mute{float:right;background-position:-25px -99px}
.no-mute.flowplayer .fp-mute{display:none}
.flowplayer .fp-volumeslider{width:90px;height:5px;cursor:col-resize;float:left;}
.is-rtl.flowplayer .fp-volumeslider{float:right}
.no-volume.flowplayer .fp-volumeslider{display:none}
.flowplayer .fp-volumelevel{height:100%}
.flowplayer .fp-time{text-shadow:0 0 1px #000;font-size:12px;font-weight:bold;color:#fff;width:100%;}
.flowplayer .fp-time.is-inverted .fp-duration{display:none}
.flowplayer .fp-time.is-inverted .fp-remaining{display:inline}
.flowplayer .fp-time em{width:35px;height:10px;line-height:10px;text-align:center;position:absolute;bottom:5px}
.no-time.flowplayer .fp-time{display:none}
.is-long.flowplayer .fp-time em{width:65px}
.flowplayer .fp-elapsed{left:5px;}
.play-button.flowplayer .fp-elapsed{left:27px}
.is-rtl.flowplayer .fp-elapsed{left:auto;right:5px;}
.play-button.is-rtl.flowplayer .fp-elapsed{right:27px}
.flowplayer .fp-remaining,.flowplayer .fp-duration{right:110px;color:#eee;}
.no-volume.flowplayer .fp-remaining,.no-volume.flowplayer .fp-duration{right:20px}
.no-mute.flowplayer .fp-remaining,.no-mute.flowplayer .fp-duration{right:100px}
.no-mute.no-volume.flowplayer .fp-remaining,.no-mute.no-volume.flowplayer .fp-duration{right:5px}
.is-rtl.flowplayer .fp-remaining,.is-rtl.flowplayer .fp-duration{right:auto;left:110px;}
.no-volume.is-rtl.flowplayer .fp-remaining,.no-volume.is-rtl.flowplayer .fp-duration{left:20px}
.no-mute.is-rtl.flowplayer .fp-remaining,.no-mute.is-rtl.flowplayer .fp-duration{left:100px}
.no-mute.no-volume.is-rtl.flowplayer .fp-remaining,.no-mute.no-volume.is-rtl.flowplayer .fp-duration{left:5px}
.flowplayer .fp-remaining{display:none}
.flowplayer.color-light .fp-time{color:#222;text-shadow:0 0 1px #fff}
.flowplayer.color-light .fp-remaining,.flowplayer.color-light .fp-duration{color:#666}
.flowplayer.aside-time .fp-time{position:absolute;top:5px;left:5px;bottom:auto !important;width:85px;}
.flowplayer.aside-time .fp-time strong,.flowplayer.aside-time .fp-time em{position:static}
.flowplayer.aside-time .fp-time .fp-elapsed{margin-right:5px;}
.is-rtl.flowplayer.aside-time .fp-time .fp-elapsed{margin-right:auto;margin-left:5px}
.flowplayer.is-long.aside-time .fp-time{width:130px}
.flowplayer.is-splash,.flowplayer.is-poster{cursor:pointer;}
.flowplayer.is-splash .fp-controls,.flowplayer.is-poster .fp-controls,.flowplayer.is-splash .fp-fullscreen,.flowplayer.is-poster .fp-fullscreen,.flowplayer.is-splash .fp-unload,.flowplayer.is-poster .fp-unload,.flowplayer.is-splash .fp-time,.flowplayer.is-poster .fp-time,.flowplayer.is-splash .fp-embed,.flowplayer.is-poster .fp-embed{display:none !important}
.flowplayer.is-poster .fp-engine{top:-9999em}
.flowplayer.is-loading .fp-waiting{display:block}
.flowplayer.is-loading .fp-controls,.flowplayer.is-loading .fp-time{display:none}
.flowplayer.is-loading .fp-ui{background-position:-9999em}
.flowplayer.is-seeking .fp-waiting{display:block}
.flowplayer.is-fullscreen{position:fixed !important;top:0 !important;left:0 !important;border:0 !important;margin:0 !important;width:100% !important;height:100% !important;max-width:100% !important;z-index:99999 !important;-webkit-box-shadow:0 !important;-moz-box-shadow:0 !important;box-shadow:0 !important;background-image:none !important;background-color:#333;}
.is-rtl.flowplayer.is-fullscreen{left:auto !important;right:0 !important}
.flowplayer.is-error{border:1px solid #909090;background:#fdfdfd !important;}
.flowplayer.is-error h2{font-weight:bold;font-size:large;margin-top:10%}
.flowplayer.is-error .fp-message{display:block}
.flowplayer.is-error object,.flowplayer.is-error video,.flowplayer.is-error .fp-controls,.flowplayer.is-error .fp-time,.flowplayer.is-error .fp-subtitle{display:none}
.flowplayer.is-ready.is-muted .fp-mute{opacity:.5;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=50)}
.flowplayer.is-mouseout .fp-controls{height:0;-webkit-transition:height .15s .3s;-moz-transition:height .15s .3s;transition:height .15s .3s}
.flowplayer.is-mouseout .fp-timeline{margin:0 !important}
.flowplayer.is-mouseout .fp-timeline{-webkit-transition:height .15s .3s,top .15s .3s,margin .15s .3s;-moz-transition:height .15s .3s,top .15s .3s,margin .15s .3s;transition:height .15s .3s,top .15s .3s,margin .15s .3s;height:4px;top:0;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0}
.flowplayer.is-mouseout .fp-fullscreen,.flowplayer.is-mouseout .fp-unload,.flowplayer.is-mouseout .fp-elapsed,.flowplayer.is-mouseout .fp-remaining,.flowplayer.is-mouseout .fp-duration,.flowplayer.is-mouseout .fp-embed,.flowplayer.is-mouseout .fp-volume,.flowplayer.is-mouseout .fp-play{opacity:0;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition:opacity .15s .3s;-moz-transition:opacity .15s .3s;transition:opacity .15s .3s}
.flowplayer.is-mouseover .fp-controls,.flowplayer.fixed-controls .fp-controls{height:20px}
.flowplayer.is-mouseover .fp-fullscreen,.flowplayer.fixed-controls .fp-fullscreen,.flowplayer.is-mouseover .fp-unload,.flowplayer.fixed-controls .fp-unload,.flowplayer.is-mouseover .fp-elapsed,.flowplayer.fixed-controls .fp-elapsed,.flowplayer.is-mouseover .fp-remaining,.flowplayer.fixed-controls .fp-remaining,.flowplayer.is-mouseover .fp-duration,.flowplayer.fixed-controls .fp-duration,.flowplayer.is-mouseover .fp-embed,.flowplayer.fixed-controls .fp-embed,.flowplayer.is-mouseover .fp-logo,.flowplayer.fixed-controls .fp-logo,.flowplayer.is-mouseover .fp-volume,.flowplayer.fixed-controls .fp-volume,.flowplayer.is-mouseover .fp-play,.flowplayer.fixed-controls .fp-play{opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer.fixed-controls .fp-volume{display:block}
.flowplayer.fixed-controls .fp-controls{bottom:-20px;}
.is-fullscreen.flowplayer.fixed-controls .fp-controls{bottom:0}
.flowplayer.fixed-controls .fp-time em{bottom:-15px;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);}
.is-fullscreen.flowplayer.fixed-controls .fp-time em{bottom:5px}
.flowplayer.is-disabled .fp-progress{background-color:#999}
.flowplayer .fp-embed{position:absolute;top:5px;left:5px;display:block;width:25px;height:20px;background-position:3px -237px;}
.is-rtl.flowplayer .fp-embed{background-position:22px -237px;left:auto;right:5px}
.flowplayer .fp-embed-code{position:absolute;display:none;top:10px;left:40px;background-color:#333;padding:3px 5px;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;-webkit-box-shadow:0 0 3px #ccc;-moz-box-shadow:0 0 3px #ccc;box-shadow:0 0 3px #ccc;font-size:12px;}
.flowplayer .fp-embed-code:before{content:'';width:0;height:0;position:absolute;top:2px;left:-10px;border:5px solid transparent;border-right-color:#333}
.is-rtl.flowplayer .fp-embed-code{left:auto;right:40px;}
.is-rtl.flowplayer .fp-embed-code:before{left:auto;right:-10px;border-right-color:transparent;border-left-color:#333}
.flowplayer .fp-embed-code textarea{width:400px;height:16px;font-family:monaco,"courier new",verdana;color:#777;white-space:nowrap;resize:none;overflow:hidden;border:0;outline:0;background-color:transparent;color:#ccc}
.flowplayer .fp-embed-code label{display:block;color:#999}
.flowplayer.is-embedding .fp-embed,.flowplayer.is-embedding .fp-embed-code{display:block;opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer.aside-time .fp-embed{left:85px;}
.is-rtl.flowplayer.aside-time .fp-embed{left:auto;right:85px}
.flowplayer.aside-time .fp-embed-code{left:115px;}
.is-rtl.flowplayer.aside-time .fp-embed-code{left:auto;right:115px}
.flowplayer.aside-time.is-embedding .fp-time{opacity:1;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100)}
.flowplayer.is-long.aside-time .fp-embed{left:130px;}
.is-rtl.flowplayer.is-long.aside-time .fp-embed{left:auto;right:130px}
.flowplayer.no-time .fp-embed{left:5px !important;}
.is-rtl.flowplayer.no-time .fp-embed{left:auto;right:5px !important}
@-moz-keyframes pulse{0%{opacity:0}
100%{opacity:1}
}@-webkit-keyframes pulse{0%{opacity:0}
100%{opacity:1}
}@-o-keyframes pulse{0%{opacity:0}
100%{opacity:1}
}@-ms-keyframes pulse{0%{opacity:0}
100%{opacity:1}
}@keyframes pulse{0%{opacity:0}
100%{opacity:1}
}.flowplayer .fp-controls{background-color:#333;background-color:rgba(51,51,51,0.6)}
.flowplayer.fixed-controls .fp-controls{background-color:#333}
.flowplayer .fp-timeline{background-color:#666}
.flowplayer .fp-buffer{background-color:#eee}
.flowplayer .fp-progress{background-color:#00a7c8}
.flowplayer .fp-volumeslider{background-color:#000}
.flowplayer .fp-volumelevel{background-color:#fff}
.flowplayer .fp-play{height:24px}
.flowplayer.is-touch.is-mouseover .fp-progress:before{background-color:#00a7c8}
.flowplayer.color-light .fp-controls{background-color:rgba(255,255,255,0.6)}
.flowplayer.color-light.fixed-controls .fp-controls{background-color:#fff}
.flowplayer.color-light .fp-volumeslider{background-color:#ddd}
.flowplayer.color-light .fp-volumelevel{background-color:#222}
.flowplayer.color-alt .fp-progress{background-color:#fff}
.flowplayer.color-alt.is-touch.is-mouseover .fp-progress:before{background-color:#fff}
.flowplayer.color-alt .fp-buffer{background-color:#999}
.flowplayer.color-alt2 .fp-progress{background-color:#900}
.flowplayer.color-alt2.is-touch.is-mouseover .fp-progress:before{background-color:#900}
